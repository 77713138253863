<script>
	import { createEventDispatcher } from 'svelte';
	import { fly } from 'svelte/transition';

	import { currentProgram, currentSession, currentCircuit, currentExercise, circuitProgress } from '../store/app.js';
	import ExerciseListItem from './ExerciseListItem.svelte';
	import Exercise from './Exercise.svelte';

	const dispatch = createEventDispatcher();

	let isBehind = false;
	let w;
	let exercises = [];

	currentCircuit.subscribe(value => {
		if(value && $currentCircuit){
			for(let i = 0; i < $currentCircuit.series; i++){
				$currentCircuit.exercises.forEach(element => {
					element.done = false;
					exercises.push(element);
				});
			}

			circuitProgress.set([exercises, 0]);
			dispatch('api_request_finished');
		}
	});

	currentExercise.subscribe(value => {
		if(!value) isBehind = false;
	});
</script>


<svelte:window bind:innerWidth={w}/>

{#if $currentCircuit}
	<section class="ciruit"
			 class:is-behind={isBehind}
			 class:is-warmup={$currentCircuit.is_warmup}
			 class:is-material={$currentSession.is_material}
			 transition:fly="{{ x: w, opacity: 1, duration: 400 }}">

		<div class="wrapper">
			<div class="header" style="background-image: url({$currentProgram.image || ''});">
				<div class="darken"></div>

				<div class="title">
					<h1>{$currentProgram.name}</h1>
					<h4><span>{$currentSession.name} / </span>{$currentCircuit.name}</h4>
				</div>
			</div>

			<div class="meta">
				<div>{$currentCircuit.series} {$currentCircuit.series > 1 ? 'vueltas' : 'vuelta'}</div>
				<div>{$currentCircuit.exercises.length} {$currentCircuit.exercises.length > 1 ? 'ejercicios' : 'ejercicio'}</div>
			</div>

			{#if $currentCircuit.description}
				<div class="info description">
					{@html $currentCircuit.description.replace(/(?:\r\n|\r|\n)/g, '<br>')}
				</div>
			{/if}

			{#if $currentCircuit.comments}
				<div class="info comments">
					{@html $currentCircuit.comments.replace(/(?:\r\n|\r|\n)/g, '<br>')}
				</div>
			{/if}

			<div class="exercises">
				<ul>
					{#each $currentCircuit.exercises as exercise}
						<ExerciseListItem exercise={exercise} />
					{/each}
				</ul>
			</div>
		</div>
	</section>

	<Exercise on:api_request_finished={() => isBehind = true} />
{/if}


<style lang="scss">
	.ciruit{
		position: fixed;
		top: var(--header-height);
		right: 0;
		bottom: 0;
		left: 0;
		background: transparent;
		overflow-y: auto;
		transition: transform 200ms ease-in-out;

		&.is-behind{
			transform: translateX(-101vw);
		}

		&.is-warmup{
			.header{
				h4{
					color: rgba(var(--color-warmup), 1);
				}
			}
		}

		&.is-material{
			.header{
				h4{
					color: rgba(var(--color-material), 1);
				}
			}
		}
	}

	.wrapper{
		min-height: 100%;
	}

	.darken{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, .34);
		z-index: 0;
	}

	.header{
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: 18vh;
		padding: 20px 40px;
		background-color: #353536;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;

		.title{
			margin-top: auto;
			margin-bottom: auto;
		}

		h1{
			position: relative;
			font-weight: 300;
			margin: 0;
		}

		h4{
			position: relative;
			margin: 10px 0 0 0;
			font-size: 1.1em;
			text-transform: uppercase;
			color: rgba(var(--color-accent), 1);

			span{
				color: #fff;
			}
		}
	}

	.meta{
		position: relative;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(1, 1fr);
		width: 100%;
		background-color: rgba(0, 0, 0, .5);
		font-size: 1.2em;

		div{
			padding: 20px;

			&:nth-child(odd){
				border-right: 1px solid rgba(255, 255, 255, .3);
			}
		}
	}

	.info{
		padding: 20px;

		&.comments{
			background: rgba(0, 0, 0, .5);
		}
	}

	.exercises{
		background: #fff;
		color: #333333;

		ul{
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}
</style>
