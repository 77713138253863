import { writable } from 'svelte/store'
import { createEventDispatcher } from 'svelte';
import { isLoading } from './app.js';
import SETTINGS from './../settings';

// returns a store with HTTP access functions for get, post, patch, delete
// anytime an HTTP request is made, the store is updated and all subscribers are notified.
export default function(initial) {
	// create the underlying store
	const store = writable(initial)
	const dispatch = createEventDispatcher();

	// define a request function that will do `fetch` and update store when request finishes
	store.request = async (method, url, params=null) => {
		isLoading.set(true);
		dispatch('api_request_started');
		// before we fetch, clear out previous errors and set `loading` to `true`
		store.update(data => {
			delete data.errors
			data.loading = true

			return data
		})

		// define headers and body
		const headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'api-key': SETTINGS.API.KEY
		}
		const body = params ? JSON.stringify(params) : undefined
		// execute fetch
		const response = await fetch(url, { method, body, headers })
		// pull out json body
		const json = await response.json()

		// if response is 2xx
		if (response.ok) {
			// update the store, which will cause subscribers to be notified
			store.set(json)
		} else {
			// response failed, set `errors` and clear `loading` flag
			store.update(data => {
				data.loading = false
				data.errors = json.errors
				return data
			})
		}

		isLoading.set(false);
		dispatch('api_request_finished');
	}

	// convenience wrappers for get, post, patch, and delete
	store.get = (url) => store.request('GET', SETTINGS.API.URL + url)
	store.post = (url, params) => store.request('POST', SETTINGS.API.URL + url, params)
	store.patch = (url, params) => store.request('PATCH', SETTINGS.API.URL + url, params)
	store.delete = (url, params) => store.request('DELETE', SETTINGS.API.URL + url, params)

	store.reset = () => store.update(data => {
		delete data.errors
		delete data.data
		data.loading = false

		return data
	});

	// return the customized store
	return store
}
