
export default {
	API: {
		KEY: null,
		URL: __isProd__ ? 'https://trainings.cirici.com/api/public/api' : 'https://local-api.alvarium.io/api',
	},
	USER: {
		UUID: null
	}
};

window.process = {
	env: {
		NODE_ENV: "production"
	}
};
