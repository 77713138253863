<script>
	import { currentCircuit } from '../store/app.js';

	export let circuit;
	export let isMaterial = false;

	const handleClick = () => {
		currentCircuit.set(circuit);
	};
</script>


<li on:click={handleClick} class:is-material={isMaterial} class:is-warmup={circuit.is_warmup}>
	<div class="listitem-left">
		{#if circuit.done}
			<svg width="26" height="26"><use xlink:href="#checked"></use></svg>
		{:else}
			<svg width="26" height="26"><use xlink:href="#unchecked"></use></svg>
		{/if}
	</div>
	<div class="listitem-center">
		<div class="name">{circuit.name}</div>
	</div>
	<div class="listitem-right"><svg width="20" height="20"><use xlink:href="#arrow"></use></svg></div>
</li>


<style lang="scss">
	li{
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;
		background: #fff;

		&:nth-child(odd){
			background: #eee;
		}

		&.is-warmup{
			background: rgba(var(--color-warmup), 1);
			border-bottom: 1px solid rgba(0, 0, 0, .3);
		}

		&.is-material{
			background: transparent;
			color: #fff;

			.listitem-center{
				color: rgba(var(--color-material), 1);
			}
		}

		.listitem-left{
			padding: 7px 15px;
			border-right: 1px solid rgba(0, 0, 0, .3);
		}

		.listitem-center{
			flex: 1;
			padding: 7px 15px;
		}

		.listitem-right{
			padding: 7px 15px;
			font-size: 2em;
			font-weight: 100;
			line-height: 0;

			svg{
				transform: rotate(180deg);
			}
		}

		.name{
			font-size: 1.2em;
			font-weight: 500;
			margin-bottom: 0;
		}
	}
</style>
