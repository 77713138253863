<script>
	import { currentSession } from '../store/app.js';

	export let session;

	const handleClick = () => {
		currentSession.set(session);
	};
</script>


<li on:click={handleClick} class="{session.done}" class:is-material={session.is_material}>
	<div class="listitem-center">
		<div class="name">{session.name}</div>
		<div class="action">
			{#if session.is_material}
				Materiales / Consejos
			{:else}
				{#if session.done === 'completed'}
					Repetir
				{:else if session.done === 'active'}
					Reanudar
				{:else}
					Empezar
				{/if}
			{/if}
		</div>
	</div>
	<div class="listitem-right"><svg width="20" height="20"><use xlink:href="#arrow"></use></svg></div>
</li>


<style lang="scss">
	li{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0;
		margin-bottom: 10px;
		background: rgba(255, 255, 255, .2);

		&.completed{
			background: rgba(var(--color-success), .4);
		}

		&.active{
			background: rgba(var(--color-accent), .7);
		}

		&.is-material{
			background: rgba(255, 255, 255, .2);

			.name{
				color: rgba(var(--color-material), 1);
			}
		}

		.listitem-center{
			padding: 10px 15px;
			flex: 1;
		}

		.name{
			font-size: 1.3rem;
			font-weight: 500;
		}

		.action{

		}

		.listitem-right{
			padding: 10px 15px;
			font-size: 2em;
			font-weight: 100;
			line-height: 0;
			color: #fff;

			svg{
				transform: rotate(180deg);
			}
		}
	}
</style>
