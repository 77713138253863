<script>
	import { createEventDispatcher} from "svelte";
	import { fly } from 'svelte/transition';
	import http from "../store/http";
	import SETTINGS from "../settings";

	const dispatch = createEventDispatcher();

	let h;
	let isBehind = false;
	let program = http({});
	let edad;
	let experiencia;
	let motivacion;
	let agua;

	$: canSubmit = edad && experiencia && motivacion && agua;

	const handleButtonClick = async () => {
		await program.post('/users/' + SETTINGS.USER.UUID + '/auto', {
			options: [edad, experiencia, motivacion, agua]
		});

		if(!$program.success){
			alert('No hemos podido encontrar un programa que se ajuste a tus necessitades.');
		}

		dispatch('refresh_programs');
	};
</script>


<svelte:window bind:innerHeight={h}/>

<section class="crea-plan"
		 class:is-behind={isBehind}
		 transition:fly="{{ y: h, opacity: 1, duration: 400 }}">

	<div class="wrapper">
		<div class="header">
			<h3>Cuestionario de autoasignación de rutinas</h3>
			<p>Massa tincidunt dui ut ornare lectus sit amet est placera</p>
		</div>

		<div class="form">
			<div class="form-group">
				<label for="edad">¿Cual es tu <strong>edad?</strong></label>
				<select id="edad" name="edad" bind:value={edad}>
					<option value="">Elige...</option>
					<option value="1">14-17</option>
					<option value="2">18-60</option>
					<option value="3">más 60</option>
				</select>
			</div>
			<div class="form-group">
				<label for="experiencia">¿Cual es tu <strong>nivel de experiencia?</strong></label>
				<select id="experiencia" name="experiencia" bind:value={experiencia}>
					<option value="">Elige...</option>
					<option value="1">Sin experiencia </option>
					<option value="2">Hasta 4 meses de exp</option>
					<option value="3">Más de 4 meses de exp</option>
				</select>
			</div>
			<div class="form-group">
				<label for="motivacion">¿Cual es tu <strong>objetivo/motivación?</strong></label>
				<select id="motivacion" name="motivacion" bind:value={motivacion}>
					<option value="">Elige...</option>
					<option value="1">Ponerse en Forma</option>
					<option value="2">Ocio Saludable</option>
					<option value="3">Estética</option>
					<option value="4">Salud</option>
					<option value="5">Senior</option>
				</select>
			</div>
			<div class="form-group">
				<label for="agua">¿Tienes acceso <strong>al agua?</strong></label>
				<select id="agua" name="agua" bind:value={agua}>
					<option value="">Elige...</option>
					<option value="1">Sí Nado</option>
					<option value="2">No Nado</option>
				</select>
			</div>
			<div class="form-actions">
				<button class="btn" disabled={!canSubmit} on:click|preventDefault={handleButtonClick}>CALCULAR</button>
			</div>
		</div>
	</div>
</section>


<style lang="scss">
	.crea-plan{
		position: fixed;
		top: var(--header-height);
		right: 0;
		bottom: 0;
		left: 0;
		background: #0B0603;
		overflow-y: auto;
		transition: transform 200ms ease-in-out;

		&.is-behind{
			transform: translateX(-101vw);
		}
	}

	.wrapper{
		min-height: 100%;
	}

	.header{
		padding: 20px 40px;

		h3{
			color: rgba(var(--color-accent), 1);
			margin-top: 0;
			margin-bottom: 7px;
		}

		p{
			font-size: 1.4em;
			margin-bottom: 20px;
		}
	}

	.form{
		padding: 20px 40px;
	}

	.form-group{
		margin-bottom: 20px;

		label{
			display: block;
			font-size: 1.3em;
			line-height: 1.2;
			margin-bottom: 7px;

			strong{
				display: block;
				font-size: 1.3em;
				text-transform: uppercase;
			}
		}

		select{
			appearance: none;
			width: 100%;
			background: url("data:image/svg+xml,%3Csvg width='19' height='11' viewBox='0 0 19 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1.5L9.5 10L1 1.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") right center no-repeat transparent;
			border: none;
			border-bottom: 1px solid #fff;
			padding: 10px 0;
			color: rgba(var(--color-accent), 1);
			font-size: 1.5em;
		}
	}

	.form-actions{
		text-align: center;

		.btn{
			margin: 20px auto 50px auto;

			&:disabled{
				opacity: .5;
			}
		}
	}
</style>
