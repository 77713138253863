<script>
	import { fly } from 'svelte/transition';

	import { currentProgram, currentSession } from '../store/app.js';
	import http from '../store/http.js'
	import SETTINGS from '../settings';

	import SessionListItem from './SessionListItem.svelte';
	import Session from './Session.svelte';

	let program = http({});
	let h;
	let isBehind = false;
	let current_tab = 0;

	currentProgram.subscribe(async value => {
		if(value) await program.get('/users/' + SETTINGS.USER.UUID + '/programs/' + value.id);
		else program.reset();
	});

	currentSession.subscribe(async value => {
		if(!value){
			isBehind = false;
			if($currentProgram) await program.get('/users/' + SETTINGS.USER.UUID + '/programs/' + $currentProgram.id);
		}
	});
</script>


<svelte:window bind:innerHeight={h}/>

{#if $program.data}
	<section class="program"
			 class:is-behind={isBehind}
			 transition:fly="{{ y: h, opacity: 1, duration: 400 }}">

		<div class="wrapper">
			<div class="header" style="background-image: url({$program.data.image || ''});">
				<div class="darken"></div>
				<h1>{$program.data.name}</h1>
			</div>

			{#if $program.data.objective}
				<div class="objetivo">
					<h4>Objetivo</h4>
					<div class="text">{@html $program.data.objective.replace(/(?:\r\n|\r|\n)/g, '<br>')}</div>
				</div>
			{/if}

			<div class="meta">
				{#if $program.data.frequency}<div><svg width="25" height="25"><use xlink:href="#clock"></use></svg> {$program.data.frequency}</div>{/if}
				{#if $program.data.intensity}<div><svg width="25" height="25"><use xlink:href="#bars"></use></svg> {$program.data.intensity}</div>{/if}
			</div>

			<div class="sessiones">
				<ul>
					{#each $program.data.sessions as session}
						<SessionListItem session={session} />
					{/each}
				</ul>
			</div>
		</div>
	</section>

	<Session on:api_request_finished={() => isBehind = true} />
{/if}

<style lang="scss">
	.program{
		position: fixed;
		top: var(--header-height);
		right: 0;
		bottom: 0;
		left: 0;
		background: transparent;
		overflow-y: auto;
		transition: transform 200ms ease-in-out;

		&.is-behind{
			transform: translateX(-101vw);
		}
	}

	.wrapper{
		min-height: 100%;
	}

	.darken{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, .34);
		z-index: 0;
	}

	.header{
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 24vh;
		padding: 20px 40px;
		background-color: #353536;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;

		h1{
			position: relative;
		}
	}

	.objetivo{
		padding: 15px 20px;
		border-bottom: 1px solid rgba(255, 255, 255, .25);

		h4{
			margin: 0 0 5px 0;
		}
	}

	.meta{
		display: flex;
		border-bottom: 1px solid rgba(255, 255, 255, .25);

		div{
			display: flex;
			align-items: center;
			flex: 1;
			padding: 15px 20px;

			svg{
				margin-right: 15px;
			}

			& + div{
				border-left: 1px solid rgba(255, 255, 255, .25);
			}
		}
	}

	.sessiones{
		padding: 15px 20px;

		ul{
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}
</style>
