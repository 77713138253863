<svg style="display: none;">
	<defs>
		<symbol id="arrow" viewBox="0 0 42 42">
			<g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round">
				<path stroke-linejoin="round" d="M22 40.25L1 20.69 22 1.12"></path>
				<path d="M1 20.55h40"></path>
			</g>
		</symbol>

		<symbol id="clock" viewBox="0 0 46 46">
			<g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="square">
				<path d="M31.13 3.06a21.41 21.41 0 0113.2 19.73 21.51 21.51 0 01-21.61 21.42A21.51 21.51 0 011.1 22.79c0-9.77 6.6-18.02 15.64-20.58"/>
				<path d="M30.49 14.95V1.23h13.85M32.98 25.5H20.44V11.23"/>
			</g>
		</symbol>

		<symbol id="bars" viewBox="0 0 40 15">
			<g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="square">
				<path d="M1 14h38M1 1h26"/>
			</g>
		</symbol>

		<symbol id="unchecked" viewBox="0 0 49 49">
			<g transform="translate(1 1)" fill="none" fill-rule="evenodd" stroke-linecap="square">
				<rect stroke="currentColor" stroke-width="2" x="1" y="1" width="45" height="45" rx="22.5"/>
			</g>
		</symbol>

		<symbol id="checked" viewBox="0 0 49 49">
			<g transform="translate(1 1)" fill="none" fill-rule="evenodd" stroke-linecap="square">
				<rect stroke="currentColor" stroke-width="2" x="1" y="1" width="45" height="45" rx="22.5"/>
				<path class="tick" stroke="currentColor" stroke-width="2" d="M10.75 23.43l8.55 9.07 16.95-18"/>
			</g>
		</symbol>

		<symbol id="cared" viewBox="0 0 19 11">
			<path d="M18 1.5L9.5 10L1 1.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</symbol>
	</defs>
</svg>
